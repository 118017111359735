.layout {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
  
.field {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.action {
    display: flex;
    flex-direction: row;
    gap: 12px;
}
  
@import '@styles/index.scss';

.layout {
  max-width: 500px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;

  span {
    flex: 1;
    width: 100%;
    text-align: center;
    display: block;
  }
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}

.image {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
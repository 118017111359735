@import '@styles/index.scss';

.layout {
  max-width: 500px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

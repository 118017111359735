@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 14px;

  border-radius: 16px;
  border: 1.3px solid #D0D5DD;
  background: #FFF;
}

.field {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.label {
  color: #98A2B3;
}

.value {
  color: #1D2939;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collectionTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-direction: column;

  &:first-child {
    flex: 1;
    width: 100%;
    overflow: hidden;
  }

  &:last-child {
    flex: 0;
  }
}

.link {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 80%;
  margin-bottom: 8px;
}

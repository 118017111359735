@import '@styles/index.scss';

.backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(16, 24, 40, 0.6);
  z-index: 6000;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 26px;
  backdrop-filter: blur(6px);

  > div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  @include mobile-vertical() {
    > div {
      height: auto;
    }
  }
}

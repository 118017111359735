@import "@styles/index.scss";

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #EAECF0;
  margin-bottom: 20px;
  color: #1D2939;

  @include mobile-horizontal() {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  @include mobile-vertical() {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
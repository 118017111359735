@import "@styles/index.scss";

$linkSize: 38px;
$iconSize: 20px;

.layout {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.list {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.item {
  display: flex;
  width: $linkSize;
  height: $linkSize;
  background: #f3f3f3;
  border-radius: 28px;
  justify-content: center;
  align-items: center;

  svg {
    width: $iconSize;
    height: $iconSize;
  }
}

.add {
  background: #f1f1f1;
  border: none;
  cursor: pointer;

  svg {
    color: #414141;
    width: 14px;
    height: 14px;
  }
}


.layout {
  display: flex;
  flex-direction: row;
  gap: 4px;
  border-radius: 16px;
  align-items: center;
  overflow: hidden;

  &.sm {
    padding: 0 8px;
    height: 22px;
  }

  &.md {
    padding: 0 10px;
    height: 24px;
  }

  &.lg {
    padding: 0 12px;
    height: 28px;
  }

  &.solid {
    &.grey {
      background: #F2F4F7;
    }

    &.blue {
      background: #D8DFF6;
    }

    &.red {
      background: #FEF3F2;
    }

    &.yellow {
      background: #FFFAEB;
    }

    &.green {
      background: #ECFDF3;
    }
  }

  &.outline {
    background: transparent;
    border-width: 1.5px;
    border-style: solid;

    &.grey {
      color: #475467;
      border-color: #475467;
    }

    &.blue {
      color: #2E4CBF;
      border-color: #2E4CBF;
    }

    &.red {
      color: #D92D20;
      border-color: #D92D20;
    }

    &.yellow {
      color: #DC6803;
      border-color: #DC6803;
    }

    &.green {
      color: #079455;
      border-color: #079455;
    }
  }
}

.icon {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 6px;
    height: 6px;
  }
}

.label {
  display: flex;
  flex: 1;
  overflow: hidden;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@import '@styles/index';

.layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.blockies {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  width: 20px;
  height: 20px;
  color: #475467;
  background: transparent;

  svg {
    width: 20px;
    height: 20px;
  }
}
@import "@styles/index.scss";

.layout {
  display: flex;
  flex-direction: column;
  gap: 22px;
  max-width: 1200px;
  padding: 73px 22px 22px;
  margin: 58px auto 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  @include mobile-horizontal() {
    position: inherit;
  }

  @include mobile-vertical() {
    position: inherit;
  }

  @include tablet-vertical() {
    position: inherit;
  }
}

.wrapperContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 60%;
  z-index: 20;
  position: relative;

  @include mobile-vertical() {
    max-width: 100%;
  }

  @include mobile-horizontal() {
    max-width: 100%;
  }

  @include tablet-vertical() {
    max-width: 100%;
  }
}

.action {
  max-width: 748px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-top: 40px;
  position: relative;
  z-index: 5;

  @include mobile-horizontal() {
    align-items: center;
    justify-content: center;
  }

  @include mobile-vertical() {
    align-items: center;
    justify-content: center;
  }

}

.wrapperDescription {
  color: #667085;
  border-bottom: 1px solid #667085;
  max-width: 600px;
  width: 80%;
  padding: 0px 48px 40px 0;

  @include mobile-horizontal() {
    max-width: 100%;
    width: 100%;
  }

  @include mobile-vertical() {
    max-width: 100%;
    width: 100%;
  }

  @include tablet-vertical() {
    max-width: 100%;
    width: 100%;
  }
}

.wrapperImage {
  right: -60px;
  top: -40px;
  width: 45%;
  height: 107%;
  position: absolute;
  background-image: url("../../../shared/assets/images/abstract-image.png");
  background-size: cover;
  background-position: top;
  border-radius: 25px;
  cursor: pointer;

  @include mobile-horizontal() {
    border-radius: 0;
    height: 570px;
    max-width: 100%;
    left: 0;
    width: 100%;

    &:after {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      content: "";
      background: linear-gradient(to bottom, #47546700 0%, #ffffff 100%);
    }
  }

  @include mobile-vertical() {
    border-radius: 0;
    height: 570px;
    max-width: 100%;
    left: 0;
    width: 100%;

    &:after {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      content: "";
      background: linear-gradient(to bottom, #47546700 0%, #ffffff 100%);
    }
  }

  @include tablet-vertical() {
    border-radius: 0;
    height: 570px;
    max-width: 100%;
    left: 0;
    width: 100%;

    &:after {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      content: "";
      background: linear-gradient(to bottom, #47546700 0%, #ffffff 100%);
    }
  }
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-top: 90px;
}

.field {
  display: flex;
  gap: 40px;
  flex-direction: column;
  position: relative;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  @include mobile-vertical() {
    align-items: center;
    justify-content: center;
  }

  @include mobile-horizontal() {
    align-items: center;
    justify-content: center;
  }
}

.grid {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  @include mobile-vertical() {
    grid-template-columns: 1fr;
  }

  @include mobile-horizontal() {
    grid-template-columns: 1fr;
  }

  @include tablet-vertical() {
    grid-template-columns: 1fr 1fr;
  }
}

.newsletter {
  display: flex;
  align-items: flex-end;
  padding: 56px 80px;
  background-image: url("../../../shared/assets/images/abstract-footer.jpg");
  background-position: center;
  background-size: cover;
  flex-direction: column;
  border-radius: 26px;
  gap: 24px;
  color: #243C97;
  width: 100%;
  max-width: 1030px;
  margin: 0 auto;

  .sales {
    display: grid;
    flex-direction: row;
    justify-content: flex-end;
  }

  @include mobile-horizontal() {
    padding: 30px;
    align-items: flex-start;
  }

  @include mobile-vertical() {
    padding: 30px;
    align-items: flex-start;
  }
}

.audience {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
}

.audienceRow {
  display: grid;
  gap: 110px;
  grid-template-columns: 1fr;
  padding-top: 100px;

  @include mobile-vertical() {
    gap: 42px;
  }

  @include mobile-horizontal() {
    gap: 42px;
  }

}

.audienceGrid {
  display: grid;
  flex-direction: row;
  justify-content: flex-start;
  grid-template-columns: 320px 320px;
  gap: 42px;

  @include mobile-vertical() {
    grid-template-columns: 1fr;
  }

  @include mobile-horizontal() {
    grid-template-columns: 1fr;
  }

  @include tablet-vertical() {
    grid-template-columns: 1fr 1fr;
  }

  &:nth-child(2) {
    grid-template-columns: 200px 320px 320px;

    @include mobile-vertical() {
      grid-template-columns: 1fr;
    }

    @include mobile-horizontal() {
      grid-template-columns: 1fr;
    }

    @include tablet-vertical() {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.star {
  display: flex;

  @include mobile-vertical() {
    align-items: center;
    justify-content: center;
  }

  @include mobile-horizontal() {
    align-items: center;
    justify-content: center;
  }

  @include tablet-vertical() {
    align-items: center;
    justify-content: center;
  }

  svg {
    color: #2E4CBF;
  }
}

.audiencePlaceholder {
  display: flex;
  flex: 1;
  align-items: flex-end;

  @include mobile-vertical() {
    display: none;
  }

  @include mobile-horizontal() {
    display: none;
  }

  @include tablet-vertical() {
    display: none;
  }
}

.audienceImage {
  background-image: url("../../../shared/assets/images/image-audience-placeholder.jpg");
  width: 400px;
  height: 592px;
  border-radius: 26px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  @include mobile-vertical() {
    display: none;
  }

  @include mobile-horizontal() {
    display: none;
  }

  @include tablet-vertical() {
    display: none;
  }
}

@import '@styles/index';

.layout {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: calc(74px + 48px) 32px 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(250px, 100%));
  gap: 22px;

  padding: 32px 0;

  @include mobile-vertical() {
    grid-template-columns: repeat(1, minmax(0%, 100%));
  }

  @include mobile-horizontal() {
    grid-template-columns: repeat(3, minmax(0%, 100%));
  }

  @include tablet-vertical() {
    grid-template-columns: repeat(3, minmax(0%, 100%));
  }

  @include tablet-horizontal() {
    grid-template-columns: repeat(4, minmax(0%, 100%));
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 22px;
  max-width: 1200px;
  padding: 73px 22px 22px;
  margin: 58px auto 0;
}

.pendingMessageBox {
  background: hsla(46, 95%, 56%, 1);
  background: linear-gradient(180deg, hsla(46, 95%, 56%, 1) 0%, hsla(350, 97%, 65%, 1) 100%);
  background: -moz-linear-gradient(180deg, hsla(46, 95%, 56%, 1) 0%, hsla(350, 97%, 65%, 1) 100%);
  background: -webkit-linear-gradient(180deg, hsla(46, 95%, 56%, 1) 0%, hsla(350, 97%, 65%, 1) 100%);
  border-radius: 6px;
  width: 100%;
}

.pendingMessageContainer {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pendingMessageText {
  display: flex;
  flex-direction: column;
}

.warning {
  &.pending {
    color: white;
    padding: 16px;
    border-radius: 6px;
  }
}

.empty {
  display: flex;
  padding: 32px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.link {
  color: red;
  text-decoration: underline;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4em 22px;
}

.stickyFooter {
  position: sticky;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  z-index: 20;
  bottom: 32px;
}
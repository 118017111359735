@import "@styles/index.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  z-index: 9;
}

.field {
  display: flex;
  flex-direction: column;
}

.description {
    background: white;
    border: 1px solid #D0D5DD;
    min-height: 250px;
    padding: 12px;
    border-radius: 8px;
  
    >div:nth-child(1) {
      background: #F9FAFB !important;
      border-radius: 80px;
      border: none;
    }
  
    >div:nth-child(2) {
      padding: 12px;
    }
  }

.actions {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  
    @include mobile-vertical() {
      justify-content: space-between;
    }
  
    @include mobile-horizontal() {
      justify-content: space-between;
    }
}


.link {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: #2E4CBF;
  margin: 8px 0;
}
@import "@styles/index";

$avatarSize: 148px;
$avatarSizeMobile: 96px;

.avatar {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  background: #EAECF0;
  border: 4px solid #FFF;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  justify-content: center;
  align-items: center;
  display: flex;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: white;
  }

  &.active {
    border-color: orange;
  }
  cursor: pointer;

  z-index: 10;
}

.text {
  color: #475467;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: white;
}

@import "@styles/index.scss";

.layout {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 14px;
  justify-content: center;
  align-items: center;

  border-radius: 28px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  &.grey {
    border: 10px solid #F9FAFB;
    background: #F2F4F7;

    svg {
      color: #475467;
    }
  }

  &.yellow {
    border: 10px solid #FFFAEB;
    background: #FEF0C7;

    svg {
      color: #DC6803;
    }
  }

  &.green {
    border: 10px solid #ECFDF3;
    background: #DCFAE6;

    svg {
      color: #079455;
    }
  }
}

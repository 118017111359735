@import "@styles/index.scss";

.layout {
	background: linear-gradient(180deg, rgba(176, 192, 224, 0.1) 0%, rgba(176, 192, 224, 0) 100%);
	padding: 4em 0;
}

.container {
	width: 100%;
	margin: 0 auto;
	max-width: 1200px;
	padding: 12px 22px;
	display: flex;
	flex-direction: column;
	gap: 160px;

	@include mobile-vertical() {
		gap: 58px;
	}

	@include mobile-horizontal() {
		gap: 58px;
	}
}

.field {
	display: flex;
	flex-direction: row;
	gap: 32px;
	margin: 0 auto;
	justify-content: space-between;
	align-items: center;
	padding-top: 100px;

	@include mobile-vertical() {
		display: block;
		gap: 50px;
		padding-top: 40px;
	}
}

.row {
	display: flex;
	flex-direction: column;
	gap: 12px;
	flex: 1;

	@include mobile-vertical() {
		flex: 0;
		text-align: center !important;
		gap: 22px;
		span {
			text-align: center !important;
		}

		&:first-child {
			position: relative;
			z-index: 2;
		}
	}
}

@import "@styles/index.scss";

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  gap: 52px;
}

.header {
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 22px;
}
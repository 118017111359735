@import "@styles/index.scss";

/** New **/
.name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  gap: 24px;

  position: relative;
  top: -27px;
  padding: 0 32px 27px;

  @include mobile-horizontal() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -58px;
    padding: 0 18px;
  }

  @include mobile-vertical() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -58px;
    padding: 0 18px;
  }
}

.avatar {
  width: 160px;
  height: 160px;

  img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @include mobile-vertical() {
    width: 142px;
    height: 142px;
  }

  @include mobile-horizontal() {
    width: 142px;
    height: 142px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 28px;

  @include mobile-horizontal() {
    padding-top: 0;
  }

  @include mobile-vertical() {
    padding-top: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #000;

  h4 {
    color: #080E23;
  }

  span {
    color: #667085;
  }

  &:nth-child(2) {
    flex: 0;
  }

  @include mobile-horizontal() {
    h2 {
      text-align: center;
    }
  }

  @include mobile-vertical() {
    h2 {
      text-align: center;
    }
  }
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 32px;

  @include mobile-vertical() {
    padding: 0 20px;
  }

  @include mobile-horizontal() {
    padding: 0 20px;
  }

  gap: 24px;
  display: flex;
  flex-direction: column;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  gap: 24px;

  position: relative;
  top: -27px;
  padding: 0 32px 27px;

  @include mobile-horizontal() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -58px;
    padding: 0 32px;
  }

  @include mobile-vertical() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -58px;
    padding: 0 32px;
  }
}

.social {
  display: flex;
  padding: 14px 0;
  gap: 16px;
  align-items: center;

  @include mobile-horizontal() {
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  @include mobile-vertical() {
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  width: 100%;
}

.network {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include mobile-horizontal() {
    flex-direction: column;
  }

  @include mobile-vertical() {
    flex-direction: column;
  }
}

.network > * {
  flex: 1;
}

.inline {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  align-items: center;

  @include mobile-horizontal() {
    flex-direction: column;
  }

  @include mobile-vertical() {
    flex-direction: column;
  }

  &.url {
    display: grid;
    grid-template-columns: 0fr 1fr;

    @include mobile-vertical() {
      grid-template-columns: 1fr;
    }

    @include mobile-horizontal() {
      grid-template-columns: 1fr;
    }
  }
}

.description {
  background: white;
  border: 1px solid #D0D5DD;
  min-height: 250px;
  padding: 12px;
  border-radius: 8px;

  >div:nth-child(2) {
    padding: 12px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.url {
  width: 100%;
}
@import '@styles/index.scss';

.layout {
  max-width: 500px;
  padding: 42px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.container {
  padding: 24px;
  display: flex;
  gap: 22px;
  flex-direction: column;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
}

.lottie {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
@import '@styles/index';

.layout {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	z-index: 1000;
	height: 72px;

	border-bottom: 1px solid #F2F4F7;
	background: #fff;
}

.container {
	margin: 0 auto;
	width: 100%;
	max-width: 1280px;
	padding: 0 32px;
	height: 72px;
	gap: 24px;
	
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.side {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 28px;

	&:last-child {
		min-width: 100px;
		justify-content: flex-end;
	}
}

.navigation {
	display: flex;
	align-items: center;
	gap: 16px;
}

.link {
	padding: 6px 8px;
}

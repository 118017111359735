.loader {
    display: flex;
    align-items: center;
    justify-content: center;

}

.full {
    position: fixed;
    inset: 0;
    background-color: #fff;
}

.section {
    padding: 4rem 0;
}
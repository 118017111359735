@import '@styles/index.scss';

.layout {
	display: flex;
	flex-direction: column;
	gap: 32px;

	@include mobile-vertical() {
		flex-direction: column-reverse;
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.field {
	display: flex;
	flex-direction: column;
	@include no-select();

	&.right {
		align-items: flex-end;
		justify-content: flex-end;
	}
}


.actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
}
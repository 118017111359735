@import "@styles/index";

.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F2F4F7;
  width: 100%;
  height: calc(240px + 72px);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &.active {
    border-color: orange;
  }

  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 72px;
  color: #1B2C71;
}

.image {
  width: 100%;
  height: 100%;
  position: relative;
}

.text {
  display: flex;
  flex-direction: column;
  color: #1B2C71;
}

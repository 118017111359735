@import "@styles/index.scss";

.layout {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mobile-vertical() {
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      text-align: center !important;
    }
  }

  @include mobile-horizontal() {
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      text-align: center !important;
    }
  }

  @include tablet-vertical() {
    align-items: center;
    justify-content: center;
    text-align: center;

    span {
      text-align: center !important;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mobile-vertical() {
    max-width: 360px;
  }

  @include mobile-horizontal() {
    max-width: 360px;
  }

  @include tablet-vertical() {
    max-width: 360px;
  }
}

.icon {
  width: 56px;
  height: 56px;

  svg {
    width: 56px;
    height: 56px;
    min-width: 56px;
    min-height: 56px;
    color: #2E4CBF;
  }
}

.description {
  color: #667085;
}
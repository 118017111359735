@import "@styles/index.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.field {
  display: flex;
  flex-direction: column;

  &.inline {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    gap: 16px;

    @include mobile-vertical() {
      grid-template-columns: 1fr 1fr;
    }

    @include mobile-horizontal() {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@import "@styles/index.scss";

.layout {
  width: 100%;
  @include no-select();
}

.input {

  gap: 8px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;


  >* {
    cursor: pointer;
  }

  input[type="checkbox"] {
    appearance: none;
    background: #ffffff;
    width: 19px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #D0D5DD;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:checked {
      background: #D8DFF6;
      border: 1px solid #243C97;

      &:after {
        content: "✔";
      }
    }
  }

  span {
    width: 100%;
  }
}

.label {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.description {
  margin-left: 22px;
  color: #475467;
}
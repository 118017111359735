@import '@styles/index.scss';

.layout {
	&.regular {
		font-weight: 400;
	}

	&.medium {
		font-weight: 500;
	}

	&.semiBold {
		font-weight: 600;
	}

	&.bold {
		font-weight: 700;
	}
}

.display {
	&.xs {
		font-size: 24px;
		line-height: 32px;
	}

	&.sm {
		font-size: 30px;
		line-height: 38px;
	}

	&.md {
		font-size: 36px;
		line-height: 44px;
	}

	&.lg {
		font-size: 48px;
		line-height: 60px;
	}

	&.xl {
		font-size: 60px;
		line-height: 72px;
	}

	&.xxl {
		font-size: 68px;
		line-height: 80px;
	}
}

.text {
	font-weight: 400;

	&.xs {
		font-size: 12px;
		line-height: 18px;
	}

	&.sm {
		font-size: 14px;
		line-height: 20px;
	}

	&.md {
		font-size: 16px;
		line-height: 24px;
	}

	&.lg {
		font-size: 18px;
		line-height: 28px;
	}

	&.xl {
		font-size: 20px;
		line-height: 30px;
	}
}

.left {
	text-align: left;
}

.center {
	text-align: center;
}

.right {
	text-align: right;
}
@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.item {
  cursor: pointer;
  color: #667085;
  padding: 0 4px 12px 4px;

  align-items: center;
  justify-content: center;
  display: flex;


  @include no-select();

  &.active {
    color: #2E4CBF;

    border-bottom: 2px solid #2E4CBF;
  }
}
.layout {
  background: white;
  min-height: 200px;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  border-bottom: 2px dashed #e0e0e0;
  padding-bottom: 12px;
}

@import '@styles/index';

.body {
	display: flex;
	background: white;
	padding: 32px;
	height: 100vh;
	overflow: auto;
	flex-direction: column;
	gap: 32px;
}


.header {
	flex: 0;
	display: flex;

	img {
		width: 154.63px;
		height: 39.6px;
	}
}

.layout {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 1;

	@include mobile-vertical() {
		padding: 0;
	}
}

.box {
	width: 360px;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.head {
	display: flex;
	flex-direction: column;

	gap: 12px;
	user-select: none;

	@include mobile-vertical() {
		align-items: center;
	}
	
	@include mobile-horizontal() {
		align-items: center;
	}
}

.link {
	margin-left: 8px;
	color: #1B2C71;
	cursor: pointer;
	font-weight: 600;
}

.foot {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
@import "@styles/index";

.banner {
  width: 100%;
  height: calc(240px + 72px);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  gap: 24px;

  position: relative;
  top: -27px;
  padding: 0 32px 27px;

  @include mobile-horizontal() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -58px;
    padding: 0 20px;
  }

  @include mobile-vertical() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: -58px;
    padding: 0 20px;
  }
}

.avatar {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  border: 4px solid #FFF;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @include mobile-vertical() {
    width: 142px;
    height: 142px;
  }

  @include mobile-horizontal() {
    width: 142px;
    height: 142px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 28px;

  @include mobile-horizontal() {
    padding-top: 0;
    flex-direction: column;
  }

  @include mobile-vertical() {
    padding-top: 0;
    flex-direction: column;
  }
}

.row {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #000;

  h4 {
    color: #080E23;
  }

  span {
    color: #667085;
  }

  &:nth-child(2) {
    flex: 1;
  }

  @include mobile-horizontal() {
    h2 {
      text-align: center;
    }
  }

  @include mobile-vertical() {
    h2 {
      text-align: center;
    }
  }
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 32px;

  gap: 24px;
  display: flex;
  flex-direction: column;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
}

.basicDataField {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 1px solid #1D2939;
  padding-bottom: 32px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
}

.grid {
  display: grid;
  gap: 22px;

  &.quests {
    grid-template-columns:  1fr;
  }

  &.nfts {
    grid-template-columns: repeat(4, minmax(250px, 100%));

    @include mobile-vertical() {
      grid-template-columns: repeat(1, minmax(0%, 100%));
    }

    @include mobile-horizontal() {
      grid-template-columns: repeat(2, minmax(0%, 100%));
    }

    @include tablet-vertical() {
      grid-template-columns: repeat(3, minmax(0%, 100%));
    }

    @include tablet-horizontal() {
      grid-template-columns: repeat(4, minmax(0%, 100%));
    }
  }

  &.rewards {
    grid-template-columns:  1fr; 
  }
}

.social {
  display: flex;
  padding: 14px 0;

  @include mobile-horizontal() {
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  @include mobile-vertical() {
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
  }
}

.collectionTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex: 0 !important;
}

.inline {
  display: flex;
  flex-direction: row;

  @include mobile-horizontal() {
    flex-direction: column;
    gap: 12px;
  }

  @include mobile-vertical() {
    flex-direction: column;
    gap: 12px;
  }
}

/*
Mobile Vertical - MV
599px - 375px ->
*/
$mobile-vertical-max: 599px;

@mixin mobile-vertical() {
    @media (max-width: $mobile-vertical-max) {
      @content;
    }
}

/*
Mobile Horizontal - MH
767px - 600px
*/
$mobile-horizontal-min: 600px;
$mobile-horizontal-max: 767px;

@mixin mobile-horizontal() {
    @media (min-width: $mobile-horizontal-min) and (max-width: $mobile-horizontal-max) {
      @content;
    }
}

/*
Tablet Vertical - TV
1023px - 768px
*/
$tablet-vertical-min: 768px;
$tablet-vertical-max: 1023px;

@mixin tablet-vertical() {
    @media (min-width: $tablet-vertical-min) and (max-width: $tablet-vertical-max) {
      @content;
    }
}

/*
Tablet Horizontal - TH
1439px - 1024px
*/
$tablet-horizontal-min: 1024px;
$tablet-horizontal-max: 1439px;

@mixin tablet-horizontal() {
    @media (min-width: $tablet-horizontal-min) and (max-width: $tablet-horizontal-max) {
      @content;
    }
}

/*
Desktop - D
<- 1024px
*/
$desktop: 1440px;

@mixin desktop() {
    @media (min-width: $desktop) {
      @content;
    }
}

@import '@styles/index.scss';

.layout {
  max-width: 500px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container {
  padding: 14px;
  display: flex;
  gap: 22px;
  flex-direction: column;
}

.banner {
  width: 100%;
  height: calc(180px + 32px);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  margin-bottom: 16px;

  @include mobile-vertical() {
    height: calc(150px + 32px);
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
}

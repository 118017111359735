
.layout {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;

  border-radius: 16px;
  border: 1.5px solid #D0D5DD;
  background: #FFF;
  align-items: center;
}

.number {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #F2F4F7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 8px;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  &.column {
    grid-template-columns: 1fr;
  }

  &.inline {
    display: flex;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;

    img {
      width: 48%;
    }
  }

  img {
    width: 100%;
    border-radius: 8px;
  }
}

.title {
  color: #667085;
}
@import '@styles/index';

.container {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding-top: 70px;
	padding-left: 32px;
	padding-right: 32px;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.header {
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #EAECF0;
	padding: 40px 0 20px;
	justify-content: space-between;
	gap: 32px;

	@include mobile-horizontal() {
		flex-direction: column;
	}

	@include mobile-vertical() {
		flex-direction: column;
	}

	@include tablet-vertical() {
		flex-direction: column;
	}
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	flex-direction: row;
	gap: 64px;

	@include mobile-horizontal() {
		grid-template-columns: 1fr;
		gap: 32px;
	}

	@include mobile-vertical() {
		grid-template-columns: 1fr;
		gap: 32px;
	}

	&.details {
		gap: 0;
	}

	&.properties {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}

.body {
	display: flex;
	flex-direction: column;
	gap: 42px;
}

.row {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.field, .inline  {
	display: flex;
	flex-direction: row;
}

.field {
	gap: 32px;

	@include mobile-horizontal() {
		flex-direction: column;
		gap: 4px;
		margin-top: 12px;
	}

	@include mobile-vertical() {
		flex-direction: column;
		gap: 4px;
		margin-top: 12px;
	}
}

.inline {
	align-items: center;
	gap: 12px;

	span {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 150px;
	}
}

.grey {
	color: #667085;
}

.description {
	flex-direction: column;
	gap: 32px;
}

.navigator {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.detailRow {
	display: flex;
	padding: 16px;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	background: #F9FAFB;
}

.label {
	color: #1D2939;
}

.value {
	color: #475467;
}

.property {
	display: flex;
	flex-direction: column;

	.value {
		color: #2E4CBF;
	}
}

.action {
	display: grid;
	flex-direction: row;
	grid-auto-columns: min-content;

	button {
		padding: 0;
	}
}

.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.desktop {
		display: block;

		@include mobile-vertical() {
			display: none;
		}
		@include mobile-horizontal() {
			display: none;
		}
	}

	&.mobile {
		display: none;

		@include mobile-vertical() {
			display: block;
		}
		@include mobile-horizontal() {
			display: block;
		}
	}
}


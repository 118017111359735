@import '@styles/index.scss';

.layout {
  max-width: 500px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.title {
  display: flex;
  padding: 33px 55px;
  flex-direction: column;
  gap: 12px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@import '@styles/index';

.layout {
	padding: 10px 12px;
	border-radius: 6px;
	background: #F9FAFB;
	height: 40px;

	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: center;

	span {
		white-space: nowrap;
	}

	&.active {
		background: #2E4CBF;
		color: #fff;

		.icon {
			svg {
				color: #fff !important;

				path {
					fill: #fff;
				}
			}
		}

		.badge {
			background: #D8DFF6;

			span {
				color: #1B2C71;
			}
		}
	}
}

.icon {
	width: 20px;
	height: 20px;

	svg {
		color: #667085;

		path {
			fill: #667085;
		}
	}
}


.badge {
	@include mobile-vertical() {
		margin-left: auto;
	}

	@include mobile-horizontal() {
		margin-left: auto;
	}

	@include tablet-vertical() {
		margin-left: auto;
	}

	@include tablet-horizontal() {
		margin-left: auto;
	}
}
$white: #FDFDFF;
$black: #010815;

// Surface
$surface-0: #0A1727;
$surface-0-5: #101C2D;
$surface-1: #152232;
$surface-2: #202D3D;
$surface-3: #2C3847;
$surface-4: #374252;
$surface-5: #424D5D;
$surface-6: #5D677A;

// Grey
$grey-primary: #BDC5D4;
$grey-1: #e6e6e6;
$grey-2: #7A8393;
$grey-3: #ececec;
$grey-4: #f3f3f3;

// Green
$green-primary: #9DF4AA;
$green-10: #84D992;
$green-20: #69BD79;
$green-30: #4DA160;
$green-40: #328749;
$green-50: #0F6D32;
$green-60: #005320;
$green-70: #003914;

// Pink
$pink-0: #FDD6FF;
$pink-10: #F2AEFF;
$pink-20-primary: #CD8DDB;
$pink-30: #FDD6FF;
$pink-40: #9D60AC;
$pink-50: #814790;
$pink-60: #672E77;
$pink-70: #4E155F;

// Cian
$cian-0: #6FF6F6;
$cian-10-primary: #4CDADA;
$cian-20: #1FBEBE;
$cian-30: #00A1A2;
$cian-40: #008585;
$cian-50: #006A6B;
$cian-60: #004F50;
$cian-70: #003738;

// Blue
$blue-0: #E6DEFF;
$blue-10: #C9BEFF;
$blue-20-primary: #AE9FFF;
$blue-30: #937EFF;
$blue-40: #785AFF;
$blue-50: #5C30FB;
$blue-60: #4100DC;
$blue-70: #2C009E;

// Red
$red-0: #FFDADD;
$red-10: #FFB2BA;
$red-20-primary: #FF8797;
$red-30: #F65A73;
$red-40: #D4415B;
$red-50: #B22744;
$red-60: #90052E;
$red-70: #67001C;

// Kiwi
$kiwi-0: #F1FEBA;
$kiwi-10-primary: #DDFC52;
$kiwi-20: #B6D329;
$kiwi-30: #9BB700;
$kiwi-40: #819B00;
$kiwi-50: #536500;
$kiwi-60: #3E4C00;

@import "@styles/index.scss";

.card {
  display: flex;
  flex-direction: column;
  border: 1px solid $black;
  padding: 12px;
  border-radius: 16px;
  border: 1px solid #D0D5DD;
  background: #FFF;

  &.open {
    .icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.title {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.icon {
  svg {
    transition: 150ms linear;
  }
}

.actions {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  @include mobile-horizontal() {
    margin-left: inherit;
  }

  @include mobile-vertical() {
    margin-left: inherit;
  }
}

.body {
  overflow: hidden;
  padding-left: 48px;
}

.description {
  font-size: 14px;
  padding-top: 8px;
  max-width: 900px;
}

.headTitle {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 12px;

  @include mobile-horizontal() {
    flex-direction: column;
  }

  @include mobile-vertical() {
    flex-direction: column;
  }
}

.inline {
  display: flex;
  flex-direction: row;
  gap: 12px;

  @include mobile-horizontal() {
    flex-direction: column;
  }

  @include mobile-vertical() {
    flex-direction: column;
  }
}
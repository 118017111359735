.layout {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
}

.title {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
}

.photo {
    border-radius: 42px;
    overflow: hidden;
}

.action {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.body {
    display: flex;
    padding-top: 18px;
    flex-direction: column;
    gap: 18px;
    border-top: 1px solid #d0d5dd;
}

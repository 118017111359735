.layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.withError {
    .container {
      background: #FFFBFA;
      border: 1px solid #F04438;
      border-radius: 8px;

      >div:nth-child(1) {
        background: #fcf2f0 !important;
        border-radius: 80px;
        border: none;
      }
    }
  }
}

.error {
  color: #D92D20;
}
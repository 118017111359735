@import '@styles/index.scss';

.modal {
  background: $white;
  position: relative;
  z-index: 1000;
  padding: 24px;

  width: 100%;

  border-radius: 12px;
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}


@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
  @include no-select();

  &.disabled {
    .inputContainer {
      background: #FCFCFD;
      border: 1px solid #EAECF0;
      border-radius: 8px;
      cursor: default;
    }

    .value {
      color: #98A2B3;
      cursor: default;
    }
  }

  &.withError {
    .inputContainer {
      background: #FFFBFA;
      border: 1px solid #F04438;
      border-radius: 8px;
    }
  }
}

.selector {
  border: none;
  background: transparent;
  width: 100%;
  outline: none;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &::placeholder {
    color: #667085;
  }
}

.inputContainer {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  height: 52px;
  padding: 0 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;

  svg {
    width: 22px;
    height: 22px;
  }
}

.selector {
  border: none;
  appearance: none;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.value {
  @include no-select();
  flex: 1;
}

.placeholder {
  color: #98A2B3;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  max-height: 200px;
  overflow: auto;
  position: absolute;
  background: white;
  z-index: 9999999;
  
  left: 0;
  right: 0;
  top: calc(100% + 16px);
}

.item {
  padding: 16px;
  cursor: pointer;
  border-radius: 8px;
  color: #344054;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    background: #F9FAFB;
  }
}
@import "@styles/index";

.body {
	display: flex;
	flex-direction: row;
	gap: 4px;
	align-items: center;
	@include no-select();
}

.separator {
	height: 20px;

	svg {
		color: #D0D5DD;
	}
}

.breadcrumb {
	display: flex;
	flex-direction: row;
	gap: 4px;
}

.link {
	border-radius: 8px;
	border: 1px solid #90A2E2;
	background: #D8DFF6;
	padding: 4px 14px;
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

	color: #1B2C71;
	cursor: pointer;
}

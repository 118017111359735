@import "@styles/index.scss";

.image {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background: #EAECF0;
  border: 4px solid #FFF;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  justify-content: center;
  align-items: center;
  display: flex;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: white;
  }

  &.active {
    border-color: orange;
  }
  cursor: pointer;

  img, video {
    width: 100%;
    height: 100%;
    aspect-ratio: 256/256;
    object-fit: cover;
  }

  z-index: 10;
}

.video {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.text {
  color: #475467;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: white;
}

.pdfContainer {
  width: 100%; /* Adjust width as needed */
  max-height: 300px; /* Adjust max height as needed */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfPage {
  max-width: 100%;
  height: auto;
}

.previewContainer {
  width: 100%;
  max-height: 300px; /* Adjust max height as needed */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewImage {
  max-width: 100%;
  height: auto;
}
@import '@styles/index.scss';

.layout {
  @include no-select();
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 16px;
  border-radius: 16px;
  border: 1.5px solid #D0D5DD;
  background: #FFF;
  width: 100%;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.icon {
  width: 32px;
  height: 32px;

  svg {
    color: #2E4CBF;
    width: 32px;
    height: 32px;
  }
}

.row {
  display: flex;
  flex-direction: column;
}

.description {
  display: flex;
  flex: 1;
  color: #98A2B3;
}

.creatorPhoto {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.label {
  color: #98A2B3;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
}
@import '@styles/index.scss';

.layout {
  max-width: 528px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  overflow: auto;

  @include mobile-horizontal() {
    left: 0;
  }

  @include mobile-vertical() {
    left: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
}
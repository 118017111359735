@import "@styles/index.scss";

.layout {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  padding: 16px;
  gap: 12px;

  &.message {
    border: 1px solid #6E85D7;
    background: transparent;

    .icon {
      svg {
        color: #2E4CBF;
      }
    }

    .container {
      h3 {
        color: #2E4CBF;
      }

      p {
        color: #2E4CBF;
      }
    }
  }

  &.warning {
    border: 1px solid #FEC84B;
    background: #FFFCF5;

    .icon {
      svg {
        color: #DC6803;
      }
    }

    .container {
      h3 {
        color: #B54708;
      }

      p {
        color: #B54708;
      }
    }
  }

  &.error {
    border: 1px solid #FDA29B;
    background: #FFFBFA;

    .icon {
      svg {
        color: #D92D20;
      }
    }

    .container {
      h3 {
        color: #B42318;
      }

      p {
        color: #B42318;
      }
    }
  }
}

.icon {
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}


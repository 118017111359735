.image {

  aspect-ratio: 256/256;
  border-radius: 16px;
  overflow: hidden;
  background: #f8f8f8;
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    aspect-ratio: 256/256;
    object-fit: cover;
  }

  img {
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }
}

.video {
  position: relative;
}
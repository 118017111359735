@import '@styles/index.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-top: 110px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding-top: 18px;
}

.banner {
  position: relative;
}

.bannerContainer {
  height: 300px;

  > div > div {
    padding-top: 0 !important;
  }
}

.avatar {
  position: absolute;
  left: 12px;
  width: 160px;
  height: 160px;
  bottom: - calc(160px / 2);
}
@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 14px;

  border-radius: 16px;
  border: 1.3px solid #D0D5DD;
  background: var(--background-color, #FFF);

  min-height: 400px;
  justify-content: space-between;
}

.field {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.fieldjustify {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 32px;
}

.label {
  color: var(--label-color, #98A2B3);
}

.value {
  color: var(--foreground-color, #1D2939);
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.collectionTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-direction: column;
}

.avatar {
    width: 48px;
    height: 48px;
    background: #F2F4F7;
    border: 4px solid #FFF;
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  
    @include mobile-vertical() {
      width: 48px;
      height: 48px;
    }
  
    @include mobile-horizontal() {
      width: 48px;
      height: 48px;
    }
}

.image {
    aspect-ratio: 256/256;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 64px;
    height: 64px;
    align-items: end;
  
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 256/256;
      object-fit: cover;
    }
  
    img {
      transform: scale(1);
      transition: all 200ms ease-in-out;
    }
}

.qr {
    width: 96px;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 32px;
}

.link {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 80%;
  margin-bottom: 8px;
}

@import '@styles/index.scss';

.layout {
  @include no-select();
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 16px;
  border-radius: 16px;
  border: 1.5px solid #D0D5DD;
  background: #FFF;
  width: 100%;
}

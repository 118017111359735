@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 14px;

  border-radius: 16px;
  border: 1.3px solid #D0D5DD;
  background: #FFF;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 32px;
}

.image {
  aspect-ratio: 256/256;
  border-radius: 16px;
  overflow: hidden;
  background: #f8f8f8;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img,
  video {
    width: 100%;
    height: 100%;
    aspect-ratio: 256/256;
    object-fit: cover;
    transition: all 200ms ease-in-out;
    transform: scale(1);
  }
}

.description {
  color: #98A2B3;
}

.label {
  color: #98A2B3;
}

.value {
  color: #1D2939;
  text-align: right;
}

.descriptionRow {
  display: flex;
  flex: 1;
}

.nftsRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
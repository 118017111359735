@import '@styles/index';

.layout {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 100px 32px 22px;

	@include mobile-vertical() {
		padding: 0;
	}
}

.box {
	background: #FFFFFF;
	width: 466px;
	box-shadow: 0 5px 9px 3px #e3e3e34f;
	border-radius: 6px;
	padding: 48px 52px;

	@include mobile-vertical() {
		border-radius: 6px;
		padding: 80px 4em 4em;
	}
}

.head {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	gap: 8px;
	user-select: none;
	margin-bottom: 60px;

	@include mobile-vertical() {
		margin-bottom: 30px;
	}
}

.link {
	margin-left: 8px;
	color: $blue-50;
	cursor: pointer;
	font-weight: 600;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 18px;
	flex-direction: column;
}

.indicator {
	min-height: 220px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lottie {
	width: 200px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
  }
@import "./index.scss";

html,
body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  color: $black;
}

body,
#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}


a {
  color: inherit;
  text-decoration: none;
}

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

input,
textarea {
  font-family: 'Inter', sans-serif !important;
}

img {
  vertical-align: middle;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

ul,
li,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}


#root {
  background-color: #fdfdfd;
}

// QuillReact read only styles
.ql-container.ql-snow {
  border: none !important;
  height: auto;

  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  padding: 4px 0px !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #667085 !important;
}


.ql-editor.ql-blank {
  height: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.react-tweet-theme {
  --tweet-container-margin: 0rem 0 !important;
}
@import "@styles/index";


.alreadyPublished {
  padding-top: 236px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.photoContainer {
  border-radius: 100%;
  background: #fff;
  padding: 8px;
  box-shadow: 0 6px 4px 0 #00000008;
}

.photo {
  border-radius: 100%;
  background: #fff;
  padding: 4px;
  border: 1px solid #f6f6f6;
}

.phrases {
  display: flex;
  flex-direction: column;
  gap: 4px;

  a {
    color: #1B2C71;
  }
}
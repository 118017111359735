@import '@styles/index.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 18px;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding-top: 18px;
}

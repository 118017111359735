@import '@styles/index';

.layout {
	padding: 12px;
	border: 1px solid #6E85D7;
	border-radius: 12px;
	background: #fff;
}

.container {
	margin: 0 auto;
	max-width: 1200px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 32px;
}

.text {
	display: flex;
	flex-direction: column;
}

.title {
	color: var(--primary-700, #2E4CBF);
}

.description {
	color: var(--primary-700, #2E4CBF);
}

@import '@styles/index.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding-top: 18px;
}

.item {
  display: grid;
  flex-direction: row;
  gap: 22px;
  grid-template-columns: 1fr 1fr;

  &.short {
    display: flex;
    grid-template-columns: 0.4fr 1fr;
  }
}

.brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  svg {
    width: 22px;
    height: 22px;
  }

  span {
    color: #2C3847;
  }
}
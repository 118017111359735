.layout {
  width: 100%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  width: 100%;
}

@import '@styles/index';

.body {
	display: flex;
	background: white;
	flex-direction: column;
	overflow-y: hidden;
	overflow-x: auto;
}

.layout {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex: 1;
	padding: 32px;

	@include mobile-vertical() {
		padding: 0;
	}
}

.box {
	width: 420px;
	display: flex;
	flex-direction: column;
	gap: 16px;

    @include mobile-vertical() {
		padding-left: 32px;
        padding-right: 32px;
	}
}

.banner {
    width: 100%;
    height: calc(180px + 32px);
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    margin-bottom: 16px;

	@include mobile-vertical() {
		height: calc(150px + 32px);
	}
}

.head {
	display: flex;
	flex-direction: column;
	user-select: none;
	align-items: center;
	justify-content: center;
}

.welcomeMessage {
	display: flex;
	align-items: center;
	justify-content: center;
}

.link {
	color: #1B2C71;
	cursor: pointer;
	font-weight: 600;
}

.foot {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 32px;

	@include mobile-vertical() {
		padding-top: 16px;
	}
}

.field {
    display: flex;
    justify-content: center;
    align-items: center;
}

.claimPassButton {
	width: 150px;
	height: 50px;

	img {
		width: 180px;
		height: 50px;
	}
}

.qr {
    width: 192px;
    height: 192px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 32px;
}

.pass {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
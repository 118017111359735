@import '@styles/index.scss';

.layout {
	display: flex;
	flex-direction: column;
	gap: 32px;

	@include mobile-vertical() {
		flex-direction: column-reverse;
	}
}

.container {
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.field {
	display: flex;
	flex-direction: column;
	@include no-select();

	&.right {
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.link {
	color: $blue-60;
	cursor: pointer;
	text-decoration: underline;
}

.security {
	margin-top: 22px;
	display: flex;
	flex-direction: column;
	border: 1px dashed #d6ccff;
	padding: 22px;
	border-radius: 4px;
	background: #faf9ff;

	.securityTitle {
		color: $blue-50;
	}
}

.scoreBoard {
	margin-top: 8px;
		> div > div {
			height: 4px !important;
			border-radius: 12px;
	}
}



@import '@styles/index.scss';

.layout {
  min-width: 300px;
  max-width: 500px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}

.image {
	border-radius: 8px;
	overflow: hidden;
	aspect-ratio: 1/1;

	&.mobile {
		display: none;
	};

	@include mobile-vertical() {
		display: none;

		&.mobile {
			display: block;
		}
	}

	@include mobile-horizontal() {
		display: none;

		&.mobile {
			display: block;
		}
	}

	img {
		object-fit: cover;
	}
}
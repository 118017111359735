@import '@styles/index.scss';

.layout {
	display: flex;
	flex-direction: row;
	border-radius: 8px;
	border: 0;
	cursor: pointer;
	width: 100%;
	align-items: center;
	justify-content: center;
	position: relative;
	@include no-select();
	pointer-events: bounding-box;

	transition: all 150ms ease;

	.text {
		white-space: nowrap;
	}

	&[disabled] {
		cursor: default;
		pointer-events: none;
	}
}

.icon {
	min-width: 20px;
	min-height: 20px;
	align-items: center;
	display: flex;
	justify-content: center;

	>svg {
		min-width: 20px;
		width: 20px;
		height: 20px;
		min-height: 20px;
	}
}

.loader {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 12px;

	svg path {
		stroke: inherit !important;
		color: inherit !important;
	}
}

.isLoading {
	opacity: 0;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.solid {
	&.primary {
		background: #2E4CBF;
		border: 1px solid #2E4CBF;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		color: #fff;
		stroke: #fff;

		&:hover {
			background: #1B2C71;
			border: 1px solid #1B2C71;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}

		&:focus {
			background: #243C97;
			border: 1px solid #243C97;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #F4EBFF;
		}

		&[disabled] {
			background: #90A2E2;
			border: 1px solid #90A2E2;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}
	}

	&.secondary {
		background: #FFFFFF;
		border: 1px solid #D0D5DD;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		color: #344054;
		stroke: #344054;

		&:hover {
			color: #1D2939;
			background: #F9FAFB;
			border: 1px solid #D0D5DD;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}

		&:focus {
			color: #344054;
			background: #FFFFFF;
			border: 1px solid #D0D5DD;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #F2F4F7;
		}

		&[disabled] {
			color: #D0D5DD;
			background: #FFFFFF;
			border: 1px solid #EAECF0;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}
	}

	&.tertiary {
		background: #D8DFF6;
		border: 1px solid #90A2E2;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		color: #1B2C71;
		stroke: #1B2C71;

		&:hover {
			color: #121E49;
			background: #B3C0EC;
			border: 1px solid #90A2E2;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}

		&:focus {
			color: #1B2C71;
			background: #D8DFF6;
			border: 1px solid #90A2E2;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
		}

		&[disabled] {
			color: #6E85D7;
			background: #FDFDFF;
			border: 1px solid #FDFDFF;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}
	}

	&.error {
		background: #D92D20;
		border: 1px solid #D92D20;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		color: #fff;

		&:hover {
			background: #B42318;
			border: 1px solid #B42318;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}

		&:focus {
			background: #D92D20;
			border: 1px solid #D92D20;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FEE4E2;
		}

		&[disabled] {
			background: #FECDCA;
			border: 1px solid #FECDCA;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}
	}

}

.outline {
	&.primary {
		background: #FFFFFF;
		border: 1px solid #D0D5DD;
		color: #344054;
		stroke: #344054;

		&:hover {
			background: #F9FAFB;
			border: 1px solid #D0D5DD;
			color: #1D2939;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:focus {
			border: 1px solid #D0D5DD;
			background: #FFF;
			color: #344054;
			box-shadow: 0 0 0 4px #F2F4F7, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
		}

		&[disabled] {
			color: #D0D5DD;
			background: #FFFFFF;
			border: 1px solid #EAECF0;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}
	}

	&.secondary {
		background: #FFFFFF;
		border: 1px solid #D0D5DD;
		color: #344054;
		stroke: #344054;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

		&:hover {
			background: #F9FAFB;
			border: 1px solid #D0D5DD;
			color: #1D2939;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&:focus {
			border: 1px solid #D0D5DD;
			background: #FFF;
			color: #344054;
			box-shadow: 0px 0px 0px 4px #F2F4F7, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}

		&[disabled] {
			color: #D0D5DD;
			background: #FFFFFF;
			border: 1px solid #EAECF0;
			box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		}
	}

	&.error {
		background: #FFFFFF;
		border: 1px solid #FDA29B;
		box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		color: #B42318;
		stroke: #B42318;

		&:hover {
			background: #FEF3F2;
			border: 1px solid #FDA29B;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
			color: #912018;
		}

		&:focus {
			background: #FFFFFF;
			border: 1px solid #FDA29B;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FEE4E2;
			color: #B42318;
		}

		&[disabled] {
			color: #FDA29B;
			background: #FFFFFF;
			border: 1px solid #FECDCA;
			box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
		}
	}
}

.ghost {
	&.primary {
		background: transparent;
		color: #475467;
		stroke: #475467;

		&:hover {
			color: #344054;
			background: #F9FAFB;
		}

		&:focus {
			color: #475467;
		}

		&[disabled] {
			color: #D0D5DD
		}
	}

	&.secondary {
		background: transparent;
		color: #1B2C71;
		stroke: #1B2C71;

		&:hover {
			background: #D8DFF6;
			color: #121E49;
		}

		&:focus {
			color: #1B2C71;
		}

		&[disabled] {
			color: #D0D5DD;
		}
	}

	&.error {
		background: transparent;
		color: #B42318;
		stroke: #B42318;

		&:hover {
			background: #FEF3F2;
			color: #912018;
		}

		&:focus {
			color: #B42318;
		}

		&[disabled] {
			color: #FDA29B;
		}
	}
}


.sm {
	height: 36px;
	padding: 0 16px;

	.text {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}
}

.md {
	height: 40px;
	padding: 0 18px;

	.text {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
	}
}

.lg {
	height: 44px;
	padding: 0 20px;

	.text {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
}

.xl {
	height: 48px;
	padding: 0 22px;

	.text {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
}

.xxl {
	height: 60px;
	padding: 0 30px;

	.text {
		font-weight: 600;
		font-size: 18px;
		line-height: 28px;
	}
}
@import "@styles/index.scss";

.layout {
  position: fixed;
  top: 68px;
  z-index: 999999999;
  width: 100%;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  @include no-select();

  @include mobile-vertical() {
    padding: 0 22px;
    width: 100%;
  }
}

.list {
  display: flex;
  gap: 8px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  max-width: 460px;
  margin: 0 auto;
}

.toast {
  border-radius: 6px;
  padding: 12px 18px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  pointer-events: all;
}

.error {
  background: #ff4a4a;
  color: white;
}

.success {
  background: #40bd37;
  color: white;
}

.close {
  width: 22px;
  height: 22px;

  cursor: pointer;
}

@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &.disabled {
    .inputContainer {
      background: #FCFCFD;
      border: 1px solid #EAECF0;
      border-radius: 8px;

      input, textarea {
        color: #98A2B3;

        &::placeholder {
          color: #98A2B3;
        }
      }
    }
  }

  &.withError {
    .inputContainer {
      background: #FFFBFA;
      border: 1px solid #F04438;
      border-radius: 8px;

      input {
        &::placeholder {
          color: #F04438;
        }
      }
    }
  }
}

.input {
  border: none;
  background: transparent;
  width: 100%;
  outline: none;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 100%;

  &::placeholder {
    color: #667085;
  }
}

.inputContainer {
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  min-height: 52px;
  gap: 12px;

  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    padding: 0 0 0 16px;

    &::placeholder {
      color: #98A2B3;
    }
  }

  textarea {
    resize: vertical;
    padding: 16px;
    min-height: 128px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;

  svg {
    width: 18px;
    height: 18px;
  }
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}

.error {
  color: #D92D20;
}

.textInputButton {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0 16px;

  svg {
    width: 20px;
    height: 20px;
    color: #667085;
  }
}
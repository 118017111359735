@import "@styles/index";

.name {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  gap: 24px;

  position: relative;
  padding: 0 32px 27px;

  @include mobile-horizontal() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  @include mobile-vertical() {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-top: 28px;

  @include mobile-horizontal() {
    padding-top: 0;
  }

  @include mobile-vertical() {
    padding-top: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #000;

  @include mobile-horizontal() {
    h4, span {
      text-align: center;
    }
  }

  @include mobile-vertical() {
    h4, span {
      text-align: center;
    }
  }
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 96px 32px 96px;

  @include mobile-vertical() {
    padding: 0 20px;
  }

  @include mobile-horizontal() {
    padding: 0 20px;
  }
}

.grid {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(3, minmax(0%, 100%));

  @include mobile-vertical() {
    padding: 0;
    grid-template-columns: repeat(1, minmax(0%, 100%));
  }

  @include mobile-horizontal() {
    padding: 0;
    grid-template-columns: repeat(1, minmax(0%, 100%));
  }

  @include tablet-vertical() {
    grid-template-columns: repeat(2, minmax(0%, 100%));
  }

  @include tablet-horizontal() {
    grid-template-columns: repeat(3, minmax(0%, 100%));
  }
}

.field {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  align-items: center;
}

.inline {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
}

.empty {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  width: 100px;
  height: 20px;
}

.actions {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  justify-content: center;
}
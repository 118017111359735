@import '@styles/index';

.layout {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: calc(74px + 48px) 32px 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0%, 100%));
  gap: 22px;

  padding: 32px 0;
}

.empty {
  display: flex;
  padding: 32px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 42px 0;
}
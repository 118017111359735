@import '@styles/index.scss';

.layout {
  display: flex;
  flex-direction: column;
  gap: 36px;

  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  padding: 0;
  overflow: hidden;

  @include tablet-horizontal() {
    left: auto;
    width: 400px;
  }

  @include tablet-vertical() {
    left: auto;
    width: 400px;
  }
}

.header {
  width: 100%;
  padding: 0 32px;
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  flex: 1;
}

.navigation {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  overflow: auto;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #F2F4F7;
  background: #fff;
  height: 70px;
}
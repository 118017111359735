@import "@styles/index.scss";

.card {
  padding: 18px;
  border-radius: 16px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.image {
  aspect-ratio: 256/256;
  border-radius: 16px;
  overflow: hidden;
  background: #f8f8f8;
  cursor: pointer;
  position: relative;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img, video {
    width: 100%;
    height: 100%;
    aspect-ratio: 256/256;
    object-fit: cover;
  }

  img {
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }
}

.video {
  position: relative;
}

.preview {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: column;
}

.label {
  color: #98A2B3;
}

.value {
  color: #1D2939;
}

.descriptionRow {
  display: flex;
  flex: 1;
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@import '@styles/index.scss';

.layout {
  max-width: 528px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 36px;

  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  overflow: auto;

  &.complete {
    overflow: inherit;
    align-items: center;
    justify-content: center;
  }

  @include mobile-horizontal() {
    left: 0;
  }

  @include mobile-vertical() {
    left: 0;
  }
}

.image {
  height: 320px;
  aspect-ratio: 256/256;
  border-radius: 16px;
  overflow: hidden;
  background: #f8f8f8;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img, video {
    width: 100%;
    height: 100%;
    aspect-ratio: 256/256;
    object-fit: cover;
    transition: all 200ms ease-in-out;
    transform: scale(1);
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.confirm {
    gap: 32px;
  }
}

.action {
  display: inline-grid;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.lottie {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
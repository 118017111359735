@import "@styles/index";

.layout {
  background: #1D2939;
  margin-top: 40px;
  padding: 40px 32px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 0 auto;
  max-width: 1200px;
}

.logo {
  width: 320px;

  @include mobile-horizontal() {
    width: 250px;
  }

  @include mobile-vertical() {
    width: 250px;
  }

  svg {
    width: 154.629px;
    height: 39.6px;
  }
}

.column {
  display: flex;
  flex-direction: row;
  gap: 32px;

  @include mobile-vertical() {
    flex-direction: column;
  }

  &.bottom {
    color: #D0D5DD;
    justify-content: space-between;
    padding-top: 32px;
    border-top: 1px solid #475467;
  }
}

.row {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    color: #fff;
  }
}

.links {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.link {
  width: 24px;
  height: 24px;

  svg {
    color: #98A2B3;
    width: 24px;
    height: 24px;
  }
}
@import '@styles/index.scss';

.layout {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.field {
	display: flex;
	flex-direction: column;
	@include no-select();

	&.right {
		align-items: flex-end;
		justify-content: flex-end;
	}
}

.link {
	margin-left: 4px;
	color: #1B2C71;
	cursor: pointer;
}

.actions {
	gap: 16px;
}

.social {
	color: #344054 !important;
	border: 1px solid #D0D5DD !important;;
	background-color: #FFF !important;
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05) !important;;
}

.security {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 24px;
	border-radius: 8px;
	margin-top: 15px;
	background: #fafbfd;
}
@import "@styles/index.scss";

.layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 352px;
  align-items: center;
  justify-content: center;

  text-align: center;
  color: #667085;
}
